import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { IconButton } from "@mui/material";
import { useCallback, useState } from "react";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  Button,
  Tooltip,
  Avatar,
} from "@mui/material";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
// hooks
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";
// atoms
import alertAtom from "../../../recoil/atoms/alertAtom";
// __apis__
// utils
import cssStyles from "../../../utils/cssStyles";
// config
import { HEADER, NAVBAR } from "../../../config";
// components
import Logo from "../../../components/Logo";
import Iconify from "../../../components/Iconify";
import { IconButtonAnimate } from "../../../components/animate";
import { logoutRequest } from "../../../__api__/auth";
import { PATH_AUTH } from "../../../routes/paths";

//
// import AccountPopover from "./AccountPopover";
// import LanguagePopover from './LanguagePopover';
// import ContactsPopover from './ContactsPopover';
// import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== "isCollapse" && prop !== "isOffset" && prop !== "verticalLayout",
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: "none",
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(["width", "height"], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up("lg")]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: "100%",
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}) {
  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive("up", "lg");

  const navigate = useNavigate();

  const user = {};

  const [loggingOut, setIsLoggingOut] = useState(false);

  const triggerAlert = useSetRecoilState(alertAtom);

  const onLoggingOutClick = useCallback(async () => {
    setIsLoggingOut(true);
    await logoutRequest()
      .then((response) => {
        triggerAlert({ triggered: true, type: "success", message: "See ya!" });
        navigate(PATH_AUTH.login);
      })
      .catch((error) => {
        triggerAlert({
          triggered: true,
          type: "error",
          message: "Couldn't log you out at the moment.",
        });
      });
    setIsLoggingOut(false);
  }, [triggerAlert, setIsLoggingOut]);

  return (
    <RootStyle
      isCollapse={isCollapse}
      isOffset={isOffset}
      verticalLayout={verticalLayout}
    >
      <Toolbar
        sx={{
          minHeight: "100% !important",
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: "text.primary" }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <Tooltip title="Account">
            <IconButton onClick={() => navigate("/account/edit-profile")}>
              <Avatar src={user?.profile_pic} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Logout">
            <IconButton onClick={onLoggingOutClick}>
              <MeetingRoomIcon />
            </IconButton>
          </Tooltip>
          {/*   <LanguagePopover /> */}
          {/*  <NotificationsPopover /> */}
          {/*  <ContactsPopover /> */}
          {/*  <AccountPopover /> */}
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
