import { useRecoilValue } from "recoil";
// atoms
// utils
import createAvatar from "../utils/createAvatar";
//
import Avatar from "./Avatar";

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const user = {};

  return (
    <Avatar
      src={user?.profile_pic}
      alt={`${user?.first_name} ${user?.last_name}`}
      color={
        user?.profile_pic
          ? "default"
          : createAvatar(`${user?.first_name} ${user?.last_name}`).color
      }
      {...other}
    >
      {createAvatar(`${user?.first_name} ${user?.last_name}`).name}
    </Avatar>
  );
}
