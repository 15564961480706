import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// paths
import { PATH_DASHBOARD, PATH_AUTH } from "./paths";
// components
import LoadingScreen from "../components/LoadingScreen";
import LayoutGenerator from "../layouts";
import DashboardLayout from "../layouts/dashboard";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <LayoutGenerator />,
    },
    //auth
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: PATH_AUTH.login, element: <LoginPage /> },
        // { path: PATH_AUTH.signup, element: <SignupPage /> },
      ],
    },
    // Overview
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { path: PATH_DASHBOARD.overview.home, element: <HomePage /> },
        {
          path: PATH_DASHBOARD.overview.puplishers,
          element: <PublishersPage />,
        },
        {
          path: PATH_DASHBOARD.overview.billingManagement,
          element: <BillingManagementPage />,
        },
        {
          path: PATH_DASHBOARD.overview.apps,
          element: <AppsPage />,
        },
        {
          path: PATH_DASHBOARD.overview.offersSurveys,
          element: <OffersSurveysPage />,
        },
        {
          path: PATH_DASHBOARD.overview.faildPostback,
          element: <FaildPostbackPage />,
        },
        {
          path: PATH_DASHBOARD.overview.settings,
          element: <SettingsPage />,
        },
      ],
    },
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

//Auth
// -- login
const LoginPage = Loadable(lazy(() => import("../pages/LoginPage")));
// -- signup
// const SignupPage = Loadable(lazy(() => import("../pages/SignupPage")));

// Overview
// -- Home
const HomePage = Loadable(lazy(() => import("../pages/HomePage")));
// -- Publishers
const PublishersPage = Loadable(lazy(() => import("../pages/PublishersPage")));
// -- BillingManagement
const BillingManagementPage = Loadable(
  lazy(() => import("../pages/BillingManagementPage"))
);
// -- Apps
const AppsPage = Loadable(lazy(() => import("../pages/AppsPage")));
// -- OffersSurveysPage
const OffersSurveysPage = Loadable(
  lazy(() => import("../pages/OffersSurveysPage"))
);
// -- FaildPostbackPage
const FaildPostbackPage = Loadable(
  lazy(() => import("../pages/FaildPostbackPage"))
);
// Settings
const SettingsPage = Loadable(lazy(() => import("../pages/SettingsPage")));
// Ledger
// Broker

const NotFound = Loadable(lazy(() => import("../pages/Page404")));
