// components
import { PATH_DASHBOARD } from "../../../routes/paths";
import Iconify from "../../../components/Iconify";
import HomeIcon from "@mui/icons-material/Home";

// ------------------------------------------------------------------------------------------------

const navConfig = [
  {
    subheader: "Overview",
    overviewItems: [
      {
        title: "Home",
        path: PATH_DASHBOARD.overview.home,
        icon: <Iconify icon="iconoir:home" />,
      },
      {
        title: "Publishers",
        path: PATH_DASHBOARD.overview.puplishers,
        icon: <Iconify icon="fluent:share-screen-person-p-16-regular" />,
      },
      {
        title: "Billing Mangement",
        path: PATH_DASHBOARD.overview.billingManagement,
        icon: <Iconify icon="formkit:dollar" />,
      },
      {
        title: "Apps",
        path: PATH_DASHBOARD.overview.apps,
        icon: <Iconify icon="fluent:apps-16-filled" />,
      },
      {
        title: "Offers & Surveys",
        path: PATH_DASHBOARD.overview.offersSurveys,
        icon: <Iconify icon="icon-park-twotone:notepad" />,
      },
      {
        title: "Failed Postback",
        path: PATH_DASHBOARD.overview.faildPostback,
        icon: <Iconify icon="pajamas:error" />, 
      },
      {
        title: "Settings",
        path: PATH_DASHBOARD.overview.settings,
        icon: <Iconify icon="uil:setting" />,
      },
    ],
  },
];

export default navConfig;

//formkit:dollar
