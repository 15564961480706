import React, { useCallback, useEffect } from "react";
// React router dom
import { useNavigate } from "react-router-dom";
// paths
import { PATH_DASHBOARD } from "../routes/paths";
//
import DashboardLayout from "./dashboard";

// -------------------------------------------------------------------------------------------------------------

function LayoutGenerator({ children }) {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(PATH_DASHBOARD.overview.home, { replace: true });
  }, [navigate]);

  return <DashboardLayout>{children}</DashboardLayout>;
}

export default LayoutGenerator;
