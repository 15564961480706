// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  // signup: path(ROOTS_AUTH, "/signup"),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  overview: {
    root: path(ROOTS_DASHBOARD, "/overview"),
    home: path(ROOTS_DASHBOARD, "/overview/home"),
    puplishers: path(ROOTS_DASHBOARD, "/overview/publishers"),
    billingManagement: path(ROOTS_DASHBOARD, "/overview/Billing-management"),
    apps: path(ROOTS_DASHBOARD, "/overview/apps"),
    offersSurveys: path(ROOTS_DASHBOARD, "/overview/offers-surveys"),
    faildPostback: path(ROOTS_DASHBOARD, "/overview/faild-postback"),
    settings: path(ROOTS_DASHBOARD, "/overview/settings"),
  },
};

export const PATH_DOCS = "https://www.basedontech.com";
